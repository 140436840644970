@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
* {
  margin: 0;
  padding: 0;
  font-family: "Montserrat", sans-serif;
  list-style-type: none;
  text-decoration: none;
  box-sizing: border-box;
}
:root {
  --p: #fa3a3a;
  --s: #0fbd6d;
  --g: #ff9800;
  --b: #e6e6e6;
  --d: #444444;
  --white: #ffffff;
  --black: #000000;
  --p-light: #ff867c;
  --p-dark: #b54a3b;
  --s-light: #2b99ff;
  --s-dark: #002e53;
  --y: #ffc000;
  --h: yellow;
  --r1: #ffaeff;
  --r11: #7030a0;
  --r2: #c5e0b4;
  --r22: #00b050;
  --r3: #9dc3e6;
  --r33: #0070c0;
  --r4: #fbe5d6;
  --r44: #cc3300;
  --border: 1px solid rgba(0, 0, 0, 0.1);
}
button {
  user-select: none;
}
#keypadTrigger {
  display: none;
}
@media screen and (max-height: 550px), (max-width: 1024px) {
  #keypadTrigger {
    display: block;
  }
}
@media screen and (max-height: 550px) {
  :root {
    --border: 1px solid rgba(255, 255, 255, 1);
  }
}
